import { HttpInterceptorsConfig } from "./httpInterceptors";
export class RelatorioTempoCicloDosagemService extends HttpInterceptorsConfig {
	async getRelatorioTempoMedioCicloDosagem (filters) {
		const response = await this.axios.get("relatorios/tempoMedioCicloDosagem", { params: { filters }});
		return response.data;
	}

	async getRelatorioTempoCicloDosagem (filters) {
		const response = await this.axios.get("relatorios/tempoCicloDosagem", { params: { filters }});
		return response.data;
	}
}
