<template>
	<two-cards
		style="max-width: 95%"
		:visible="mostraRelatorio"
		:errMsg="errMsg"
		:alertClass="alertClass"
	>
		<template #header>
			<h4 class="card-title">Pesquisar</h4>
		</template>

		<template #one>
			<form @submit.prevent="findAll">
				<div class="form-row">
					<div class="form-group col-sm-6">
						<label><b>Injetora</b></label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="injetora"
							search
							:filters="multiSelectFilters[0]"
							searchPlaceholder="Pesquisar"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="injetoraOptions"
							data-cy="Injetora"
							:btnLabel="() => (injetora.length > 1 ? `${injetora[0].nome}...` : injetora[0] && injetora[0].nome) || 'Selecione'"
						/>
					</div>
					<div class="form-group col-sm-6">
						<label><b>Status das ordens de Produção</b></label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="statusOrdensProducao"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="statusOrdensProducaoOptions"
							data-cy="Ordens de Produção"
							:btnLabel="() => statusOrdensProducao[0] && statusOrdensProducao[0].nome || 'Selecione'"
						/>
					</div>
				</div>
				<div class="form-row">
					<div class="col form-group col-sm-6">
						<label><b>Data e Hora inicial</b></label>
						<div class="d-flex">
							<datepicker
								class="w-100"
								placeholder="Início"
								id="data-inicial-historica"
								input-class="bg-white"
								v-model="filtros.inicio"
								@selected="filtroRapido(FILTROS_RAPIDOS[0])"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Inicial"
							></datepicker>
							<input
								id="hora-inicial-historica"
								type="time"
								class="form-control time-pick"
								v-model="filtros.horaInicial"
								@input="(v) => hoursListener('hora_inicial', v)"
							/>
						</div>
					</div>
					<div class="col form-group col-sm-6">
						<label><b>Data e Hora final</b></label>
						<div class="d-flex">
							<datepicker
								class="w-100"
								placeholder="Fim"
								id="data-final-historica"
								input-class="bg-white"
								v-model="filtros.fim"
								@selected="filtroRapido(FILTROS_RAPIDOS[0])"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Final"
							></datepicker>
							<input
								id="hora-final-historica"
								type="time"
								class="form-control time-pick"
								v-model="filtros.horaFinal"
								@input="hoursListener('hora_final')"
							/>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="col form-group col-sm-6">
						<label><b>Filtros rápidos</b></label>
						<div>
							<b-button
								v-for="(f, idx) in FILTROS_RAPIDOS"
								:key="idx"
								@click="btnFiltroRapido(f)"
								class="filter-button rounded-pill"
								:pressed.sync="f.pressed"
							>
								{{ f.nome }}
							</b-button>
						</div>
					</div>
					<div class="form-group col-sm-3">
						<label><b>Agrupamento da média</b></label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="agrupamentoMedia"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="agrupamentoMediaOptions"
							data-cy="Agrupamento da média"
							:btnLabel="() => agrupamentoMedia[0] && agrupamentoMedia[0].nome || 'Selecione'"
						/>
					</div>
				</div>
				<div class="d-flex justify-content-end tcs-card-footer-action">
					<button
						:disabled="!filtros.inicio || !filtros.fim || !agrupamentoMedia.length || !statusOrdensProducao.length"
						class="btn btn-success tcs-btn"
						type="submit"
						data-cy="Pesquisar"
					>
						<SearchIcon/>
					</button>
				</div>
			</form>
		</template>
		<template #two>
			<tbody v-if="dataLoading">
				<tb-skeleton shape="rect" theme="opacity"></tb-skeleton>
			</tbody>
			<div v-else>
				<section>
					<div style="display: flex; justify-content: space-between;">
						<b-button
							@click="openModalColunasMedia()"
							class="button-colunas mb-2"
						>
							<SettingsIcon class="mr-2"/>
							Alternar Colunas
						</b-button>
						<b-button
							@click="openGrafico()"
							class="button-grafico mb-2"
						>
							<BarChart2Icon class="mr-2"/>
							Visualizar Gráfico
						</b-button>
					</div>
					<div class="modal-select-colunas" :class="{'modal-select-colunas-visible': mostraModalColunasMedia }">
						<div class="modal-select-colunas-content">
							<h3>Selecione Colunas para Tempo Médio de Ciclo e Dosagem</h3>
							<div class="lista-colunas">
								<ul class="my-3">
									<li v-for="item in optionsColunasMedia" :key="item.id">
										<input type="checkbox" v-model="item.selecionada" :id="'coluna-media-' + item.id">
										<label :for="'coluna-media-' + item.id" class="mb-0 ml-1">{{ item.coluna }}</label>
									</li>
								</ul>
							</div>
							<div class="btn-modal-select-colunas">
								<b-button style="width: 40%;" variant="outline-primary" @click="salvaSelectColunasMedia()">Salvar</b-button>
								<b-button style="width: 40%;" @click="cancelaSelectColunasMedia()">Cancelar</b-button>
							</div>
						</div>
					</div>
					<div v-if="mediaRelatorio.length">
						<h4>Tempo Médio de Ciclo e Dosagem</h4>
						<b-row class="mt-2 ml-0">
							<b-pagination
								id="page"
								v-model="currentPageMedia"
								:total-rows="paginationMedia.count"
								:per-page="paginationMedia.limit.value"
							/>
						</b-row>
						<div class="head-row mt-2 mb-2">
							<span class="text-head-row ml-1">
								Resultados {{ paginationMedia.offset + 1 }}-{{ paginationMedia.offset + mediaRelatorio.length }} de {{ paginationMedia.count }}
							</span>
							<b-form
								class="text-head-row">
								<label class="mr-2">
									Itens por página:
								</label>
								<b-form-select
									class="mr-1 mb-2"
									v-model="paginationMedia.limit.value"
									name="itens-por-pagina"
									:options="optionsItensPorPag"
									@input="changeItensByPageMedia()"
								/>
							</b-form>
						</div>
					</div>
					<DataTable
						class="data-table"
						:loading="loading"
						:colunas="colunasMedia"
						:linhas="mediaRelatorio"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="media"
						:hasPagination="false"
						:noedit="true"
						@clickSortTableBy="(v) => sortTableMediaBy(v)"
						:state="{
							sortBy: sortMedia.sortBy,
							sortAsc: sortMedia.order === 1,
							query: ''
						}"
						:async="true"
					/>
				</section>
				<section class="mt-4 border-top">
					<b-button
						@click="openModalColunasEventos()"
						class="button-colunas mt-4 mb-2"
					>
						<SettingsIcon class="mr-2"/>
						Alternar Colunas
					</b-button>
					<div class="modal-select-colunas" :class="{'modal-select-colunas-visible': mostraModalColunasEventos }">
						<div class="modal-select-colunas-content">
							<h3>Selecione Colunas para Tempo Médio de Ciclo e Dosagem</h3>
							<div class="lista-colunas">
								<ul class="my-3">
									<li v-for="item in optionsColunasEventos" :key="item.id">
										<input type="checkbox" v-model="item.selecionada" :id="'coluna-' + item.id">
										<label :for="'coluna-' + item.id" class="mb-0 ml-1">{{ item.coluna }}</label>
									</li>
								</ul>
							</div>
							<div class="btn-modal-select-colunas">
								<b-button style="width: 40%;" variant="outline-primary" @click="salvaSelectColunasEventos()">Salvar</b-button>
								<b-button style="width: 40%;" @click="cancelaSelectColunasEventos()">Cancelar</b-button>
							</div>
						</div>
					</div>
					<div v-if="eventosRelatorio.length">
						<h4>Tempo de Ciclo e Dosagem</h4>
						<b-row class="mt-2 ml-0">
							<b-pagination
								id="page"
								v-model="currentPageEventos"
								:total-rows="paginationEventos.count"
								:per-page="paginationEventos.limit.value"
							/>
						</b-row>
						<div class="head-row mt-2 mb-2">
							<span class="text-head-row ml-1">
								Resultados {{ paginationEventos.offset + 1 }}-{{ paginationEventos.offset + eventosRelatorio.length }} de {{ paginationEventos.count }}
							</span>
							<b-form
								class="text-head-row">
								<label class="mr-2">
									Itens por página:
								</label>
								<b-form-select
									class="mr-1 mb-2"
									v-model="paginationEventos.limit.value"
									name="itens-por-pagina"
									:options="optionsItensPorPag"
									@input="changeItensByPageEventos()"
									:disabled="loading"
								/>
							</b-form>
						</div>
					</div>
					<DataTable
						class="data-table"
						:loading="loading"
						:colunas="colunasEventosTable"
						:linhas="eventosRelatorio"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="eventos"
						:hasPagination="false"
						:noedit="true"
						@clickSortTableBy="(v) => sortTableEventosBy(v)"
						:state="{
							sortBy: sortEventos.sortBy,
							sortAsc: sortEventos.order === 1,
							query: ''
						}"
						:async="true"
					/>
				</section>
			</div>
			<b-modal
				ref="modalGrafico"
				id="modalGrafico"
				hide-footer
				hide-header
				size="xl"
				@hidden="onModalHidden"
			>
				<div>
					<b-overlay
						:show="isBusy"
						rounded="sm"
						class="dashboard-overlay"
					>
					<div class="div-chart-head">
						<div
							class="div-chart-export"
							@click="exportGrafico"
						>
							<FilePlusIcon class="export-title"/>
							<span
								class="export-title"
							>
								Exportar
							</span>
						</div>
						<div class="div-chart-close">
							<XIcon
								class="btn-chart-close"
								@click="closeGrafico"
							/>
						</div>
					</div>
					<div class="div-chart">
						<label class="chart-title">
							Gráficos Gerais - Histórico de Tempos de Ciclo e Dosagem Médios
						</label>
						<b-tabs ref="tabs" class="tabs">
							<b-tab title="Tempo de Ciclo" class="border border-top-none">
								<div class="p-3 echarts-chart">
									<Echarts
										ref="chartTemposCiclo"
										autoresize
										:options="optionsTemposCiclos"
									/>
								</div>
							</b-tab>
							<b-tab title="Tempo de Dosagem" class="border border-top-none">
								<div class="p-3 echarts-chart">
									<Echarts
										ref="chartTemposDosagem"
										autoresize
										:options="optionsTemposDosagem"
									/>
								</div>
							</b-tab>
						</b-tabs>
					</div>
					</b-overlay>
				</div>
			</b-modal>
			<div id="graficoToPDF" v-if="showDivGraphicExport">
				<div id="export-chart-1" class="echarts-chart-export">
					<Echarts
						autoresize
						:options="optionsTemposCiclosToExport"
					/>
				</div>
				<div id="export-chart-2" class="echarts-chart-export">
					<Echarts
						autoresize
						:options="optionsTemposDosagemToExport"
					/>
				</div>
			</div>
		</template>
	</two-cards>
</template>

<script>
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import Datepicker from "vuejs-datepicker";
	import VueMultiSelect from "vue-multi-select";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import Echarts from "vue-echarts";
	import "echarts/lib/chart/line";
	import "echarts/lib/component/axis";
	import "echarts/lib/component/dataZoom";
	import "echarts/lib/component/legend";
	import "echarts/lib/component/markLine";
	import "echarts/lib/component/title";
	import "echarts/lib/component/toolbox";
	import "echarts/lib/component/tooltip";

	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";

	import DataTable from "@/components/DataTable";
	import TwoCards from "@/templates/TwoCards.vue";

	import { numberFormatted } from "@/helpers/common";
	import { MaquinasService } from "@/services/maquinas";
	import { RelatorioTempoCicloDosagemService } from "@/services/relatorioTempoCicloDosagem";

	import html2canvas from "html2canvas";
	import jsPDF from "jspdf";

	export default {
		name: "relatorioTempoCicloDosagem",
		components: {
			TwoCards,
			Datepicker,
			VueMultiSelect,
			DataTable,
			Echarts
		},

		data () {
			return {
				ptBR,
				filtros: {
					inicio: null,
					fim: null,
					horaInicial: "00:00",
					horaFinal: "23:59",
					rapido: 0
				},

				FILTROS_RAPIDOS: [{
					nome: "Personalizado",
					pressed: true
				}, {
					nome: "Última hora",
					inicio: [-1, "h"],
					fim: [0, "h"],
					agrupamento: "Hora",
					intervalo: 300,
					pressed: false
				}, {
					nome: "Último dia",
					inicio: [-1, "day"],
					fim: [0, "days"],
					agrupamento: "Hora",
					intervalo: 300,
					pressed: false
				}, {
					nome: "Última semana",
					inicio: [-1, "week"],
					fim: [0, "weeks"],
					agrupamento: "Hora",
					intervalo: 1800,
					pressed: false
				}, {
					nome: "Último mês",
					inicio: [-1, "month"],
					fim: [0, "months"],
					agrupamento: "Dia",
					intervalo: 3600,
					pressed: false
				}],

				maquinasService: new MaquinasService(),
				relatorioTempoCicloDosagemService: new RelatorioTempoCicloDosagemService(),
				injetoraOptions: [],
				injetora: [],
				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true)
				],
				loading: true,
				statusOrdensProducao: [{
					nome: "Ativadas/Desativadas",
					value: 0
				}],
				statusOrdensProducaoOptions: [
					{
						nome: "Ativadas/Desativadas",
						value: 0
					},
					{
						nome: "Ativadas",
						value: 1
					},
					{
						nome: "Desativadas",
						value: 2
					}
				],
				agrupamentoMedia: [{
					nome: "Por Día",
					value: 1
				}],
				agrupamentoMediaOptions: [
					{
						nome: "Por Hora",
						value: "hora"
					},
					{
						nome: "Por Día",
						value: "dia"
					},
					{
						nome: "Por Semana",
						value: "semana"
					},
					{
						nome: "Por Mês",
						value: "mes"
					}

				],
				dataLoading: false,
				errMsg: "Selecione as variáveis que deseja visualizar.",
				mediaRelatorio: [],
				eventosRelatorio: [],
				mostraRelatorio: false,
				alertClass: "alert-info",
				currentPageMedia: 1,
				paginationMedia: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPageEventos: 1,
				paginationEventos: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 50
					},
					pages: 1
				},
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				optionsColunasMedia: [
					{ id: 1, coluna: "Injetora", selecionada: true },
					{ id: 2, coluna: "Agrupamento", selecionada: true },
					{ id: 3, coluna: "Ordem de Produção", selecionada: true },
					{ id: 4, coluna: "Alertas", selecionada: true },
					{ id: 5, coluna: "Tempo de Ciclo Teórico", selecionada: true },
					{ id: 6, coluna: "Tempo de Dosagem Teórico", selecionada: true },
					{ id: 7, coluna: "Tempo Médio de Ciclo", selecionada: true },
					{ id: 8, coluna: "Tempo Médio de Dosagem", selecionada: true },
					{ id: 9, coluna: "Quantidade de Ciclos Concluídos", selecionada: true },
					{ id: 10, coluna: "Peças Produzidas", selecionada: true }
				],
				optionsColunasEventos: [
					{ id: 1, coluna: "Injetora", selecionada: true },
					{ id: 2, coluna: "Data/Hora", selecionada: true },
					{ id: 3, coluna: "Ordem de Produção", selecionada: true },
					{ id: 4, coluna: "Tempo de Ciclo Teórico", selecionada: true },
					{ id: 5, coluna: "Tempo de Dosagem Teórico", selecionada: true },
					{ id: 6, coluna: "Tempo de Ciclo Real", selecionada: true },
					{ id: 7, coluna: "Tempo de Dosagem Real", selecionada: true }
				],
				optionsColunasMediaOld: [],
				optionsColunasEventosOld: [],
				colunasMedia: [],
				colunasEventos: [],
				mostraModalColunasMedia: false,
				mostraModalColunasEventos: false,
				ordensProducaoConsulta: [],
				startTime: null,
				endTime: null,
				colunasEventosTable: [],
				resultMedia: {},
				resultEventos: {},
				sortMedia: {
					sortBy: 1,
					order: -1
				},
				sortEventos: {
					sortBy: 1,
					order: -1
				},
				isBusy: false,
				optionsTemposCiclos: {
					title: {
						textStyle: { fontWeight: 500 },
						text: "Histórico do Tempo de Ciclo Médio",
						subtextStyle: {
							fontWeight: "bold",
							color: "gray"
						}
					},
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "55px"
					}, {
						id: "dataZoomXInside",
						type: "inside",
						xAxisIndex: [0],
						filterMode: "empty"
					}, {
						id: "dataZoomY",
						type: "slider",
						yAxisIndex: [0],
						filterMode: "empty"
					}],
					tooltip: {
						trigger: "item",
						formatter: params => `
							${params.marker}
							${params.seriesName || params.data.name} <br>
							${!params.data.name ? params.name : "Média"}<br>
							${!params.data.name ? "Tempo de Ciclo Médio:" : ""} ${(params.value / 1000).toFixed(3).replace(".", ",")} seg
						`
					},
					toolbox: {
						show: true,
						right: "10px",
						feature: {
							restore: {
								show: true,
								title: "Restaurar"
							},
							mark: { show: true },
							saveAsImage: {
								show: true,
								title: "Salvar Imagem"
							},
							dataView: {
								show: true,
								readOnly: true,
								title: "Dados",
								lang: ["Dados em uso:", "Fechar", "Recarregar"]
							}
						}
					},
					legend: {
						show: true,
						data: [],
						bottom: "5px",
						textStyle: {
							color: "black"
						}
					},
					grid: {
						left: "10px",
						right: "50px",
						bottom: "45px",
						top: "110px",
						containLabel: true
					},
					xAxis: {
						type: "category",
						show: true,
						data: [],
						axisLabel: {
							rotate: 30
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							formatter: v => (v / 1000).toFixed(3).replace(".", ",")
						}
					},
					series: []
				},
				optionsTemposDosagem: {
					title: {
						textStyle: { fontWeight: 500 },
						text: "Histórico do Tempo de Dosagem Médio",
						subtextStyle: {
							fontWeight: "bold",
							color: "gray"
						}
					},
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "55px"
					}, {
						id: "dataZoomXInside",
						type: "inside",
						xAxisIndex: [0],
						filterMode: "empty"
					}, {
						id: "dataZoomY",
						type: "slider",
						yAxisIndex: [0],
						filterMode: "empty"
					}],
					tooltip: {
						trigger: "item",
						formatter: params => `
							${params.marker}
							${params.seriesName || params.data.name} <br>
							${!params.data.name ? params.name : "Média"}<br>
							${!params.data.name ? "Tempo de Dosagem Médio:" : ""} ${(params.value / 1000).toFixed(3).replace(".", ",")} seg
						`
					},
					toolbox: {
						show: true,
						right: "10px",
						feature: {
							restore: {
								show: true,
								title: "Restaurar"
							},
							mark: { show: true },
							saveAsImage: {
								show: true,
								title: "Salvar Imagem"
							},
							dataView: {
								show: true,
								readOnly: true,
								title: "Dados",
								lang: ["Dados em uso:", "Fechar", "Recarregar"]
							}
						}
					},
					legend: {
						show: true,
						data: [],
						bottom: "5px",
						textStyle: {
							color: "black"
						}
					},
					grid: {
						left: "10px",
						right: "50px",
						bottom: "45px",
						top: "110px",
						containLabel: true
					},
					xAxis: {
						type: "category",
						show: true,
						data: [],
						axisLabel: {
							rotate: 30
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							formatter: v => (v / 1000).toFixed(3).replace(".", ",")
						}
					},
					series: []
				},
				showExportar: true,
				showDivGraphicExport: false,
				optionsTemposCiclosToExport: {},
				optionsTemposDosagemToExport: {}
			};
		},

		watch: {
			currentPageMedia () {
				this.changePageMedia();
			},

			currentPageEventos () {
				this.changePageEventos();
			}
		},

		async mounted () {
			this.loading = true;
			this.injetoraOptions = (await this.maquinasService.listAllMachines()) || [];
			this.loading = false;

			this.optionsColunasMediaOld = this.optionsColunasMedia.map(ele => ele.selecionada);
			this.optionsColunasEventosOld = this.optionsColunasEventos.map(ele => ele.selecionada);

			this.colunasMedia = this.optionsColunasMedia.reduce((acc, ocm) => {
				if (ocm.selecionada)
					acc.push(ocm.coluna);

				return acc;
			}, []);

			this.colunasEventos = this.optionsColunasEventos.reduce((acc, oce) => {
				if (oce.selecionada)
					acc.push(oce.coluna);

				return acc;
			}, []);

			this.btnFiltroRapido(this.FILTROS_RAPIDOS[2]);
		},

		methods: {
			onModalHidden () {
				this.showDivGraphicExport = false;
			},

			unPressedFiltrosRapidos () {
				this.FILTROS_RAPIDOS.forEach(fr => fr.pressed = false);
			},

			hoursListener (campo) {
				if ((campo === "hora_inicial" && this.filtros.horaInicial !== "00:00") || (campo === "hora_final" && this.filtros.horaFinal !== "23:59")) {
					this.unPressedFiltrosRapidos();
					this.FILTROS_RAPIDOS[0].pressed = true;
				}
			},

			filtroRapido (fr) {
				this.unPressedFiltrosRapidos();
				this.filtros.rapido = fr;
				fr.pressed = true;

				if (fr.nome === "Última hora") {
					this.filtros.horaInicial = ("0" + dayjs().add(-1, "hour").hour()).slice(-2) + ":" + ("0" + dayjs().minute()).slice(-2);
					this.filtros.horaFinal = ("0" + dayjs().hour()).slice(-2) + ":" + ("0" + dayjs().minute()).slice(-2);
				} else {
					this.filtros.horaInicial = "00:00";
					this.filtros.horaFinal = "23:59";
				}

				if (fr.inicio) {
					this.filtros.inicio = dayjs()
						.add(...fr.inicio)
						.toDate();
				}

				if (fr.fim) {
					this.filtros.fim = dayjs()
						.add(...fr.fim)
						.toDate();
				}
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			async btnFiltroRapido (fr) {
				if (this.filtros.rapido.nome !== fr.nome) {
					this.unPressedFiltrosRapidos();
					this.filtroRapido(fr);
				}
				fr.pressed = true;
			},

			async findAll () {
				this.dataLoading = true;
				if (this.currentPageMedia === 1)
					await this.pesquisar();
				else
					this.currentPageMedia = 1;

				if (this.currentPageEventos === 1)
					this.pesquisarEventos();
				else
					this.currentPageEventos = 1;
			},

			async pesquisar () {
				const startTime = dayjs(this.filtros.inicio)
					.set("hour", this.filtros.horaInicial.split(":")[0])
					.set("minute", this.filtros.horaInicial.split(":")[1])
					.set("second", 0).toDate();

				const endTime = dayjs(this.filtros.fim)
					.set("hour", this.filtros.horaFinal.split(":")[0])
					.set("minute", this.filtros.horaFinal.split(":")[1])
					.set("second", 59).toDate();

				if (startTime > endTime) {
					this.$swal({
						title: "Data inicial maior do que final!",
						html: "<p>Escolha novamente as datas.</p>",
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
					return;
				}

				this.startTime = startTime;
				this.endTime = endTime;

				const idMaquinas = this.injetora.map(i => i.id);
				const statusOrdensProducao = this.getStatusOrdensProducaoSearch();
				const agrupamentoMedia = this.agrupamentoMedia[0].value;

				const limit = this.paginationMedia.limit.value;
				this.paginationMedia.offset = (this.paginationMedia.page - 1) * limit;
				const offset = this.paginationMedia.offset;
				const sort = { ...this.sortMedia };
				if (sort.order)
					sort.order = sort.order === 1 ? "ASC" : "DESC";

				const filters = { idMaquinas, statusOrdensProducao, startTime, endTime, agrupamentoMedia, limit, offset, sort };

				try {
					this.resultMedia = await this.relatorioTempoCicloDosagemService.getRelatorioTempoMedioCicloDosagem(filters) || {};
					this.mediaRelatorio = this.computaMediaRelatorio();

					this.paginationMedia.count = this.resultMedia.count || 0;
					this.paginationMedia.pages = this.paginationMedia.count > limit ? Math.ceil(this.paginationMedia.count / limit) : 1;

					if (this.mediaRelatorio.length) {
						this.mostraRelatorio = true;
					} else {
						this.errMsg = "Nenhum registro foi encontrado para os filtros selecionados.";
						this.mostraRelatorio = false;
						this.alertClass = "alert-warning";
					}

					this.dataLoading = false;
					this.ordensProducaoConsulta = this.mediaRelatorio.map((op) => op.id_ordem_producao);
					this.optionsTemposCiclos.title.subtext = `Início: ${dayjs(startTime).format("DD/MM/YYYY HH:mm")}. Fim: ${dayjs(endTime).format("DD/MM/YYYY HH:mm")}.`;
					this.optionsTemposDosagem.title.subtext = `Início: ${dayjs(startTime).format("DD/MM/YYYY HH:mm")}. Fim: ${dayjs(endTime).format("DD/MM/YYYY HH:mm")}.`;
				} catch (error) {
					console.error(error);
				} finally {
					this.dataLoading = false;
				}
			},

			getStatusOrdensProducaoSearch () {
				if (this.statusOrdensProducao[0].value === 1)
					return { ativa: true };
				else if (this.statusOrdensProducao[0].value === 2)
					return { ativa: false };

				return null;
			},

			async changePageMedia () {
				this.paginationMedia.page = this.currentPageMedia;
				await this.pesquisar();
			},

			changeItensByPageMedia () {
				this.paginationMedia.page = 1;
				this.pesquisar();
			},

			changePageEventos () {
				this.paginationEventos.page = this.currentPageEventos;
				this.pesquisarEventos();
			},

			changeItensByPageEventos () {
				this.paginationEventos.page = 1;
				this.pesquisarEventos();
			},

			async pesquisarEventos () {
				if (!this.ordensProducaoConsulta.length || !this.startTime || !this.endTime) return;

				const limit = this.paginationEventos.limit.value;
				this.paginationEventos.offset = (this.paginationEventos.page - 1) * limit;
				const offset = this.paginationEventos.offset;
				const idsOrdensProducao = this.ordensProducaoConsulta;
				const startTime = this.startTime;
				const endTime = this.endTime;
				const sort = { ...this.sortEventos };
				if (sort.order)
					sort.order = sort.order === 1 ? "ASC" : "DESC";

				const filters = { idsOrdensProducao, startTime, endTime, limit, offset, sort };

				try {
					this.resultEventos = await this.relatorioTempoCicloDosagemService.getRelatorioTempoCicloDosagem(filters) || {};
					this.computaEventosRelatorios();

					this.paginationEventos.count = this.resultEventos.count || 0;
					this.paginationEventos.pages = this.paginationEventos.count > limit ? Math.ceil(this.paginationEventos.count / limit) : 1;
				} catch (error) {
					console.error(error);
				}
			},

			openModalColunasMedia () {
				this.mostraModalColunasMedia = true;
			},

			openModalColunasEventos () {
				this.mostraModalColunasEventos = true;
			},

			salvaSelectColunasMedia () {
				const temColunas = this.optionsColunasMedia.filter((ele) => ele.selecionada);
				if (!temColunas.length)
					return;

				this.mostraModalColunasMedia = false;
				this.optionsColunasMediaOld = this.optionsColunasMedia.map((ele) => ele.selecionada);
				this.colunasMedia = this.optionsColunasMedia.reduce((acc, ocm) => {
					if (ocm.selecionada)
						acc.push(ocm.coluna);

					return acc;
				}, []);

				this.mediaRelatorio = this.computaMediaRelatorio();
			},

			salvaSelectColunasEventos () {
				const temColunas = this.optionsColunasEventos.filter((ele) => ele.selecionada);
				if (!temColunas.length)
					return;

				this.mostraModalColunasEventos = false;
				this.optionsColunasEventosOld = this.optionsColunasEventos.map((ele) => ele.selecionada);
				this.colunasEventos = this.optionsColunasEventos.reduce((acc, ocm) => {
					if (ocm.selecionada)
						acc.push(ocm.coluna);

					return acc;
				}, []);

				this.computaEventosRelatorios();
			},

			cancelaSelectColunasMedia () {
				this.optionsColunasMedia.forEach((ele, idx) => {
					ele.selecionada = this.optionsColunasMediaOld[idx];
					return ele;
				});
				this.mostraModalColunasMedia = false;
			},

			cancelaSelectColunasEventos () {
				this.optionsColunasEventos.forEach((ele, idx) => {
					ele.selecionada = this.optionsColunasEventosOld[idx];
					return ele;
				});
				this.mostraModalColunasEventos = false;
			},

			sortTableMediaBy (v) {
				this.sortMedia = v;
				this.pesquisar();
			},

			sortTableEventosBy (v) {
				this.sortEventos = v;
				this.pesquisarEventos();
			},

			openGrafico () {
				this.showDivGraphicExport = true;
				this.getGrafico(this.optionsTemposCiclos, "tempo_medio_ciclo");
				this.getGrafico(this.optionsTemposDosagem, "tempo_medio_dosagem");

				this.optionsTemposCiclosToExport = Object.assign({}, this.optionsTemposCiclos);
				Reflect.deleteProperty(this.optionsTemposCiclosToExport, "toolbox");
				Reflect.deleteProperty(this.optionsTemposCiclosToExport, "dataZoom");

				this.optionsTemposDosagemToExport = Object.assign({}, this.optionsTemposDosagem);
				Reflect.deleteProperty(this.optionsTemposDosagemToExport, "toolbox");
				Reflect.deleteProperty(this.optionsTemposDosagemToExport, "dataZoom");

				this.$refs.modalGrafico.show();
			},

			closeGrafico () {
				this.$refs.modalGrafico.hide();
			},

			getGrafico (dataChart, key) {
				dataChart.xAxis.data = this.resultMedia.rows.reduce((acc, row) => {
					const agrupamento = row.agrupamento;
					const existe = acc.some(agrupa => agrupa === agrupamento);
					if (!existe)
						acc.push(agrupamento);

					return acc;
				}, []);

				dataChart.series = this.resultMedia.rows.reduce((acc, row) => {
					const tempo = row[key];
					const injetora = acc.filter(maquina => maquina.serieId === row.idMaquina);
					if (injetora.length) {
						injetora[0].data.push(tempo);
						return acc;
					}

					acc.push({
						name: row.injetora,
						type: "line",
						symbol: "roundRect",
						symbolSize: 5,
						data: [ tempo ],
						markLine: {
							data: [{
								type: "average",
								name: row.injetora,
								label: {
									formatter (params) {
										const seriesName = params.data.name;
										const value = (Number(params.value) / 1000).toFixed(3).replace(".", ",");
										return "Média " + seriesName + " - " + value + " seg.";
									},
									position: "insideStartBottom",
									distance: 8
								}
							}]
						},
						serieId: row.idMaquina
					});
					return acc;
				}, []);
				dataChart.legend.data = dataChart.series.map(data => data.name);
			},

			async exportGrafico () {
				try {
					const chartPDF = new jsPDF({
						orientation: "landscape"
					});
					let element = document.getElementById("graficoToPDF").querySelector("#export-chart-1");
					const width = element.offsetWidth;
					const height = element.offsetHeight;
					const pdfWidth = 297;
					const pdfHeight = 210;
					const scaleFactor = Math.min(pdfWidth / width, pdfHeight / height);
					const scaleWidth = width * scaleFactor;
					const scaleHeight = height * scaleFactor;
					const xPos = (pdfWidth - scaleWidth) / 2 + 7;
					const yPos = (pdfHeight - scaleHeight) / 2;

					let canvas = await html2canvas(element);
					chartPDF.addImage(canvas.toDataURL("image/png"), "PNG", xPos, yPos, scaleWidth, scaleHeight);

					chartPDF.addPage();
					element = document.getElementById("graficoToPDF").querySelector("#export-chart-2");
					canvas = await html2canvas(element);
					chartPDF.addImage(canvas.toDataURL("image/png"), "PNG", xPos, yPos, scaleWidth, scaleHeight);

					chartPDF.save("graficos_tempos_ciclo_dosagem.pdf");
				} catch (e) {
					console.log(e);
				}
			},

			computaMediaRelatorio () {
				return this.resultMedia.rows ? this.resultMedia.rows.reduce((acc, ele) => {
					const tempoMedioCiclo = (`${(ele.tempo_medio_ciclo / 1000).toFixed(3)} seg`).replace(".", ",");
					const tempoMedioDosagem = (`${(ele.tempo_medio_dosagem / 1000).toFixed(3)} seg`).replace(".", ",");
					const pecasProduzidas = ele.pecas_produzidas;
					const ciclosConcluidos = ele.ciclos_concluidos;
					const tempoCicloTeorico = (`${(ele.tempo_ciclo_teorico / 1000).toFixed(3)} seg`).replace(".", ",");
					const tempoDosagemTeorico = (`${(ele.tempo_dosagem_teorico / 1000).toFixed(3)} seg`).replace(".", ",");

					const registro = {
						id_ordem_producao: ele.id_ordem_producao,
						["Injetora"]: ele.injetora || "",
						["Agrupamento"]: ele.agrupamento,
						["Ordem de Produção"]: ele.ordem_producao || "",
						["Alertas"]: ele.alertas,
						["Tempo de Ciclo Teórico"]: tempoCicloTeorico,
						["Tempo de Dosagem Teórico"]: tempoDosagemTeorico,
						["Tempo Médio de Ciclo"]: tempoMedioCiclo,
						["Tempo Médio de Dosagem"]: tempoMedioDosagem,
						["Quantidade de Ciclos Concluídos"]: `${ciclosConcluidos} ciclos`,
						["Peças Produzidas"]: `${pecasProduzidas} peças`
					};

					const row = {
						id_ordem_producao: registro.id_ordem_producao
					};
					row.cols = this.colunasMedia.map((cm) => registro[cm] || "");
					acc.push(row);
					return acc;
				}, []) : [];
			},

			computaEventosRelatorios () {
				this.eventosRelatorio = this.resultEventos.rows ? this.resultEventos.rows.reduce((acc, ele) => {
					const excedidoTempoCiclo = ele.tempo_ciclo_real > ele.tempo_ciclo_teorico;
					const tempoCicloReal = {
						value: (`${(ele.tempo_ciclo_real / 1000).toFixed(3)} seg`).replace(".", ","),
						alert: excedidoTempoCiclo,
						number: true
					};

					const excedidoTempoDosagem = ele.tempo_dosagem_real > ele.tempo_dosagem_teorico;
					const tempoDosagemReal = {
						value: (`${(ele.tempo_dosagem_real / 1000).toFixed(3)} seg`).replace(".", ","),
						alert: excedidoTempoDosagem,
						number: true
					};

					const dataFinal = new Date(ele.data_final);
					const miliSegundos = String(dataFinal.getMilliseconds()).padStart(3, "0");

					const registro = {
						["Injetora"]: ele.injetora || "",
						["Ordem de Produção"]: ele.ordem_producao || "",
						["Tempo de Ciclo Teórico"]: (`${(ele.tempo_ciclo_teorico / 1000).toFixed(3)} seg`).replace(".", ","),
						["Tempo de Dosagem Teórico"]: (`${(ele.tempo_dosagem_teorico / 1000).toFixed(3)} seg`).replace(".", ","),
						["Tempo de Ciclo Real"]: tempoCicloReal,
						["Tempo de Dosagem Real"]: tempoDosagemReal,
						["Data/Hora"]: `${dayjs(dataFinal).format("DD/MM/YYYY HH:mm:ss")},${miliSegundos}`
					};

					const row = {};
					row.cols = this.colunasEventos.map((ce) => registro[ce] || "");
					acc.push(row);
					return acc;
				}, []) : [];

				this.colunasEventosTable = this.colunasEventos.reduce((acc, ele) => {
					if (ele !== "Tempo de Ciclo Real" && ele !== "Tempo de Dosagem Real")
						acc.push(ele);

					return acc;
				}, []);

				const { alertasTempoCiclo, alertasTempoDosagem } = this.resultEventos.alertas;

				if (this.colunasEventos.some((ele) => ele === "Tempo de Ciclo Real")) {
					if (alertasTempoCiclo)
						this.colunasEventosTable.push({ value: "Tempo de Ciclo Real", alert: numberFormatted(alertasTempoCiclo)});
					else
						this.colunasEventosTable.push("Tempo de Ciclo Real");
				}

				if (this.colunasEventos.some((ele) => ele === "Tempo de Dosagem Real")) {
					if (alertasTempoDosagem)
						this.colunasEventosTable.push({ value: "Tempo de Dosagem Real", alert: numberFormatted(alertasTempoDosagem)});
					else
						this.colunasEventosTable.push("Tempo de Dosagem Real");
				}

				return;
			}
		}
	};
</script>

<style scoped>
	.lista-colunas {
		max-height: 13rem;
		overflow-x: auto;
		margin-top: 20px;
		margin-bottom: 30px;
		border-bottom: solid 1px #d0d1d2;
		border-top: solid 1px#d0d1d2;
	}

	.modal-select-colunas {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9999;
		display: none;
		justify-content: center;
		align-items: center;
	}

	.modal-select-colunas-visible {
		display: flex;
	}

	.modal-select-colunas-content {
		background-color: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
		height: 25rem;
		width: 30rem;
	}

	.btn-modal-select-colunas {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.button-colunas {
		display: flex;
		margin-left: -1rem;
		border-radius: 0 12px 12px 0;
		background: #4E4B46;
		padding: 10px;
	}

	.filter-button {
		margin-right: 15px;
		border-radius: 10px;
		color: #696969;
		background-color: white;
		margin-bottom: 3px;
	}

	.filter-button:hover {
		color: white;
		background-color: #696969;
	}

	.filter-button-pressed {
		color: white;
		background-color: #20B2AA;
	}

	.btn.active {
		color: white!important;
		background-color: #20B2AA!important;
	}

	.double-switch {
		display: flex;
		justify-content: space-evenly;
	}

	.switch-options {
		margin-top: 2%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.label-text {
		width: 20rem;
	}

	.text-head-row {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
	}

	.data-table {
		overflow-y: auto;
	}

	#hora-inicial-historica,
	#hora-final-historica {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.button-grafico {
		display: flex;
		margin-right: -1rem;
		border-radius: 12px 0 0 12px;
		background: #FF8C00;
		border-color: #FF8C00;
		padding: 10px;
	}

	.div-chart-head {
		display: flex;
		align-content: space-between !important;
		padding-bottom: 5px;
		border-bottom: 1px solid #CED4DA;
	}

	.div-chart-export {
		display: flex;
		align-items: center;
	}

	.div-chart-close {
		margin-left: auto;
		float: right;
	}

	.btn-chart-close {
		cursor: pointer;
	}

	.export-title {
		color: #FF8C00;
		font-size: 20px;
		padding-left: 5px;
		cursor: pointer;
	}

	.chart-title {
		width: 100%;
		text-align: center;
		font-size: 30px;
		color: #FFFFFF;
		background-color: #727272;
		border-radius: 5PX;
		margin-bottom: 20px;
	}

	.echarts {
		width: 100%;
		height: 70vh;
		min-height: 500px;
	}

	.div-chart {
		background-color: #FFFFFF;
		border-radius: 5px;
		padding: 2rem;
		padding-bottom: 0;
	}

	.border-top-none {
		border-top: none !important;
	}

	.display-chart-export {
		display: none;
	}

	.echarts-chart-export {
		position: fixed !important;
		width: 120vh !important;
	}

	@media (min-width: 640px) {
		.echarts-chart-export {
			width: 900px !important;
		}
	}
</style>

<style>
	#data-inicial-historica,
	#data-final-historica {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
</style>
